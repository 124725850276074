<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="400px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="close"
    class="global-org-vue--new-form-dialog"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="120px"
      class="global-org-vue--new-form"
      size="small"
    >
      <el-form-item label="商品名称" prop="name">
        <el-input
          v-model="form.name"
          placeholder="请输入商品名称"
          maxlength="20"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="商品类型" prop="type">
        <el-select v-model="form.type">
          <el-option :value="1" label="普通商品">普通商品</el-option>
          <el-option :value="2" label="私域商品">私域商品</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="库存" prop="amount">
        <el-input-number
          v-model="form.amount"
          step-strictly
          :min="1"
          :max="9999"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="商品组合" prop="group_name">
        <el-select v-model="form.group_name" clearable>
          <el-option
            v-for="(v, i) in groupList"
            :key="i"
            :label="v.group_name"
            :value="v.group_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品分类" prop="category_name">
        <el-select v-model="form.cates" clearable multiple collapse-tags>
          <el-option
            v-for="(v, i) in categoryList"
            :key="i"
            :label="v.category_name"
            :value="v.category_id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.group_name" label="组内缩写标签" prop="abbr">
        <el-input
          v-model="form.abbr"
          placeholder="请输入组内缩写标签"
          maxlength="20"
          show-word-limit
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submitForm"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { fetchGroupList, fetchCategoryList, fetchEditGoods } from "@/api/goods";

export default {
  name: "EditGoods",
  props: {
    // 当前类型
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        name: "", // 商品名称
        goods_id: "", // 商品ID
        type: 1,
        abbr: "",
        amount: "",
        group_name: "",
        cates: [],
      },
      groupList: [],
      categoryList: [],
      rules: {
        name: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        abbr: [
          { required: true, message: "请输入组内缩写标签", trigger: "blur" },
        ],
      },
      loading: false,
    };
  },
  computed: {
    title() {
      return this.type == "add" ? "添加商品" : "修改商品";
    },
  },
  methods: {
    // 打开弹窗
    open(goods) {
      this.getGroupList();
      this.getCategoryList();
      this.dialogVisible = true;
      this.$nextTick(() => {
        if (this.type == "mod") {
          let cates = goods.cates || []
          this.form = {
            ...goods,
            cates: cates.map(item => item.category_id)
          };
        }
      });
    },
    getGroupList() {
      fetchGroupList().then((res) => {
        const list = res.data || [];
        this.groupList = list;
      });
    },
    getCategoryList() {
      fetchCategoryList().then((res) => {
        const list = res.data || [];
        this.categoryList = list;
      });
    },
    // 关闭
    close() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
      this.form = this.$options.data().form;
    },
    // 提交
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let params = {
            name: this.form.name,
            type: this.form.type,
            amount: this.form.amount,
            edit_type: this.type,
            abbr: this.form.abbr,
            group_name: this.form.group_name,
            cates: this.form.cates
          };
          if (this.type == "mod") {
            params.goods_id = this.form.goods_id;
          }
          this.loading = true;
          fetchEditGoods(params)
            .then(() => {
              this.loading = false;
              this.$message.success("操作成功");
              this.$emit("refresh");
              this.close();
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
