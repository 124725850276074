
import fetch from '@/utils/fetch'
/**
 * 获取商品组合
 */
export function fetchGroupList(params) {
  return fetch({
    url: '/admin/goods/groups',
    method: 'GET',
    params,
  })
}

/**
 * 新增/编辑商品组合
 */
export function fetchUpdateGroup(data) {
  return fetch({
    url: '/admin/goods/group_edit',
    method: 'POST',
    data,
  })
}

/**
 * 获取商品分类
 */
export function fetchCategoryList(params) {
  return fetch({
    url: '/admin/goods/cates',
    method: 'GET',
    params,
  })
}

/**
 * 新增/编辑商品分类
 */
export function fetchUpdateCategory(data) {
  return fetch({
    url: '/admin/goods/cate_edit',
    method: 'POST',
    data,
  })
}

/**
 * 获取商品列表
 */
export function fetchGoodsList(params) {
  return fetch({
    url: '/admin/goods/list',
    method: 'GET',
    params,
  })
}

/**
 * 获取商品详情
 */
export function fetchGoodsDetail(params) {
  return fetch({
    url: '/admin/goods/info',
    method: 'GET',
    params,
  })
}

/**
 * 新增/编辑/删除商品
 */
export function fetchEditGoods(data) {
  return fetch({
    url: '/admin/goods/edit',
    method: 'POST',
    data,
  })
}

/**
 * 编辑/删除商品图片
 */
export function fetchEditGoodsPicture(data) {
  return fetch({
    url: '/admin/goods/edit_picture',
    method: 'POST',
    data,
  })
}

/**
 * 上传商品图片
 */
export function fetchUpdateGoodsPicture(data) {
  return fetch({
    url: '/admin/goods/upload_pictures',
    method: 'POST',
    data,
  })
}

/**
 * 编辑商品附加信息
 */
export function fetchUpdateGoodsTxtra(data) {
  return fetch({
    url: '/admin/goods/extra',
    method: 'POST',
    data,
  })
}

/**
 * 编辑商品排序
 */
export function fetchUpdateGoodsSort(data) {
  return fetch({
    url: '/admin/goods/edit_picture_batch',
    method: 'POST',
    data,
  })
}
