<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="800px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="close"
    class="global-org-vue--new-form-dialog"
  >
    <el-form
      ref="form"
      :model="form"
      label-width="100px"
      class="global-org-vue--new-form"
      size="small"
    >
      <el-form-item label="商品ID" prop="name">
        <el-input
          v-model="form.goods_id"
          disabled
          placeholder="请输入商品ID"
          maxlength="20"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="商品名称" prop="name">
        <el-input
          v-model="form.name"
          disabled
          placeholder="请输入商品名称"
          maxlength="20"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="主图" prop="name">
        <upload-picture
          :limit="4"
          :list="mainPictures"
          id="main"
          @success="onSuccess($event, 1)"
          @remove="onRemove($event, 1)"
          @sort="onSort($event, 1)"
        ></upload-picture>
      </el-form-item>
      <el-form-item label="详情图" prop="name">
        <upload-picture
          :limit="4"
          :list="detailPictures"
          id="detail"
          @success="onSuccess($event, 2)"
          @remove="onRemove($event, 2)"
          @sort="onSort($event, 2)"
        ></upload-picture>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import {
  fetchUpdateGoodsPicture,
  fetchEditGoodsPicture,
  fetchGoodsDetail,
  fetchUpdateGoodsSort,
} from "@/api/goods";
import UploadPicture from "@/components/upload-picture/index.vue";
export default {
  name: "EditGoods",
  components: {
    UploadPicture,
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        name: "", // 商品名称
        goods_id: "", // 商品ID
        type: 0,
        amount: "",
        group_name: "",
        contents: [],
      },
      title: "图片管理",
      mainPictures: [],
      detailPictures: [],
    };
  },
  methods: {
    onSuccess({ file }, type) {
      const pictures = {
        resource_id: file.response.key,
        type,
      };
      this.uploadGoodsPicture(pictures).then(() => {
        this.getGoodsDetail();
      });
    },
    onRemove({ file }, type) {
      const data = {
        resource_id: file.response.key,
        edit_type: "del",
        type,
      };
      fetchEditGoodsPicture(data).then(() => {
        this.getGoodsDetail();
      });
    },
    onSort({ sorts }, type) {
      let arr = type == 1 ? this.mainPictures : this.detailPictures;
      let resources = [];
      sorts.forEach((item, index) => {
        let pic = arr.find((p) => p.id == item);
        if (pic) {
          resources.push({
            resource_id: item,
            type: pic.type,
            sequence: index + 1,
          });
        }
      });
      const data = {
        edit_type: "mod",
        resources,
      };
      fetchUpdateGoodsSort(data)
        .then(() => {
          this.getGoodsDetail();
        })
        .catch(() => {});
    },
    uploadGoodsPicture(pictures) {
      const data = {
        goods_id: this.form.goods_id,
        pictures,
      };
      return fetchUpdateGoodsPicture(data);
    },
    // 打开弹窗
    async open(goods) {
      this.form = { ...goods };
      await this.getGoodsDetail();
      this.dialogVisible = true;
    },
    getGoodsDetail() {
      return new Promise((resolve) => {
        fetchGoodsDetail({ goods_id: this.form.goods_id })
          .then((res) => {
            const contents = res.data.contents || [];
            this.mainPictures = contents
              .filter((item) => item.type == 1)
              .map((item) => {
                return {
                  url: item.url,
                  id: item.resource_id,
                  type: item.type,
                  response: {
                    key: item.resource_id,
                  },
                  sequence: item.sequence,
                };
              });
            this.detailPictures = contents
              .filter((item) => item.type == 2)
              .map((item) => {
                return {
                  url: item.url,
                  id: item.resource_id,
                  type: item.type,
                  response: {
                    key: item.resource_id,
                  },
                  sequence: item.sequence,
                };
              });
            resolve();
          })
          .catch(() => {
            this.mainPictures = [];
            this.detailPictures = [];
            resolve();
          });
      });
    },
    // 关闭
    close() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
      this.form = this.$options.data().form;
      this.mainPictures = [];
      this.detailPictures = [];
      this.$emit("refresh");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .hide {
  .el-upload {
    display: none;
  }
}
</style>
